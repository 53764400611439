var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "del" }, [
    _c("h3", [_vm._v("删除好友")]),
    _c("br"),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.friendUserId,
          expression: "friendUserId",
        },
      ],
      staticClass: "input",
      attrs: { type: "text", placeholder: "好友id" },
      domProps: { value: _vm.friendUserId },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.friendUserId = $event.target.value
        },
      },
    }),
    _c("br"),
    _c("br"),
    _c("div", { staticClass: "fun_group" }, [
      _c("div", { staticClass: "sure btn", on: { click: _vm.doDelete } }, [
        _vm._v("确定"),
      ]),
      _c(
        "div",
        {
          staticClass: "can btn",
          on: {
            click: function ($event) {
              return _vm.$router.push({ name: "test" })
            },
          },
        },
        [_vm._v("返回")]
      ),
      _c(
        "div",
        {
          staticClass: "btn",
          on: {
            click: function ($event) {
              return _vm.$router.push({ name: "fun_task_index" })
            },
          },
        },
        [_vm._v("回到首页")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }