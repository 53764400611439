<!--
 * @Author       : Hugo
 * @Date         : 2020-09-18 11:03:55
 * @LastEditors  : Hugo
 * @LastEditTime : 2020-09-18 11:35:51
 * @Description  : 
 * @FilePath     : \miaohang\src\views\test\DelContact.vue
-->
<template>
  <div class="del">
    <h3>删除好友</h3>
    <br>
    <input
      type="text"
      class="input"
      v-model="friendUserId"
      placeholder="好友id"
    >
    <br>
    <br>
    <div class="fun_group">
      <div class="sure btn" @click="doDelete">确定</div>    
      <div class="can btn" @click="$router.push({name: 'test'})">返回</div>
      <div class="btn" @click="$router.push({name: 'fun_task_index'})">回到首页</div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      friendUserId: '',
    }
  },
  methods:{
    async doDelete(){
      let {friendUserId} = this;
      if(friendUserId){
        let res = await this.$http.request('/projectFriends/deleteFriend', {friendUserId});
        alert(res.message);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.del{
  @include bbox;
  width: 100%;
  height: 100%;
  padding: 40px;
  font-family: 'MicroSoft JhengHei';
  .input{
    width: 300px;
    padding: 0 10px;
    line-height: 30px;
  }
  .fun_group{
    @include flex;
    .btn{
      cursor: pointer;
      line-height: 30px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-right: 20px;
      padding: 0 20px;
    }
  }
}
</style>